import React, { useEffect, useState } from "react";
import _ from "lodash";
import { AppImage } from "../components";
import { Layout } from "../components";
import processDate from "../utils/processDate";
import RichTextRender from "../components/RichTextRender";
import { graphql } from "gatsby";

import {
  FacebookIconRound,
  LinkedInIcon,
  TwitterIcon,
  LinkIcon,
  MissingAuthorIcon,
  MissingHeroImage,
} from "../components";

import {
  // FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

const BlogTemplate = (props) => {
  const contentfulBlog = _.get(props, "data.contentfulBlog");
  // parse out the rich text from the contentful return
  const contentfulRichText = contentfulBlog.bodyText;
  const [blogURL, setBlogURL] = useState("");

  useEffect(() => {
    if (window) {
      setBlogURL(window.location.href);
    }
  }, []);

  const copyURL = () => {
    navigator.clipboard.writeText(blogURL).then(
      () => {
        alert("COPIED URL TO CLIPBOARD");
      },
      () => {
        alert("FAILED TO COPY URL TO CLIPBOARD");
      }
    );
  };

  const shareMediaIcons = (
    // Links to share blog on social Media
    <>
      <div className="social-icons-row">
        {/*<FacebookShareButton className="social-icon" url={blogURL}>
          <FacebookIconRound />
        </FacebookShareButton>*/}
        <TwitterShareButton className="social-icon" url={blogURL}>
          <TwitterIcon />
        </TwitterShareButton>
        <LinkedinShareButton className="social-icon" url={blogURL}>
          <LinkedInIcon />
        </LinkedinShareButton>
        <div
          className="social-icon"
          role="button"
          aria-label="Copy Link"
          tabIndex={0}
          onClick={copyURL}
          onKeyPress={copyURL}
        >
          <LinkIcon />
        </div>
      </div>
      <div>SHARE</div>
    </>
  );

  const authorSocialLinks = (author) => {
    const socialLinks = _.get(author, "socialLinks");
    // If the author supplies social links, render the icon with the proper URL
    return (
      <div className="author-social-icons">
        {_.find(socialLinks, ["title", "Facebook"]) ? (
          <a
            href={_.get(_.find(socialLinks, ["title", "Facebook"]), "url")}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <FacebookIconRound />
          </a>
        ) : null}
        {_.find(socialLinks, ["title", "Twitter"]) ? (
          <a
            href={_.get(_.find(socialLinks, ["title", "Twitter"]), "url")}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
          >
            <TwitterIcon />
          </a>
        ) : null}
        {_.find(socialLinks, ["title", "LinkedIn"]) ? (
          <a
            href={_.get(_.find(socialLinks, ["title", "LinkedIn"]), "url")}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <LinkedInIcon />
          </a>
        ) : null}
      </div>
    );
  };

  const headerContent = (
    <div
      className={`content-wrapper${
        _.get(contentfulBlog, "imageHasText") ? " image-has-text" : ""
      }`}
    >
      <div className="share-media-mobile">{shareMediaIcons}</div>
      <h1 className="title">
        {_.get(contentfulBlog, "title.internal.content")}
      </h1>
      <div className="header-details-wrapper">
        <div className="header-details-left">
          {/* Author Headshot image with a default Image if user doesn't supply one */}
          {(_.get(contentfulBlog, "authors") || []).map((author, index) => {
            if (_.get(author, "image")) {
              return (
                <div key={index} className="author-headshot">
                  <AppImage image={_.get(author, "image")} />
                </div>
              );
            } else {
              return (
                <div key={index} className="author-headshot">
                  <MissingAuthorIcon />
                </div>
              );
            }
          })}
          <div className="author-details">
            <div className="h4">
              {(_.get(contentfulBlog, "authors") || []).map((author, index) => {
                return `${_.get(author, "name")}${
                  index < _.get(contentfulBlog, "authors").length - 1
                    ? " and "
                    : ""
                }`;
              })}
            </div>
            {_.get(contentfulBlog, "date") ||
            _.get(contentfulBlog, "readTime") ? (
              <div className="small">
                {_.get(contentfulBlog, "date")
                  ? processDate(_.get(contentfulBlog, "date"))
                  : null}
                {_.get(contentfulBlog, "readTime")
                  ? ` • ${_.get(contentfulBlog, "readTime")}`
                  : ""}
              </div>
            ) : null}
          </div>
        </div>
        <div className="share-media-desktop">{shareMediaIcons}</div>
      </div>
    </div>
  );

  return (
    <>
      <Layout
        className="templated-blog-page"
        pageData={contentfulBlog}
        // Header component to render through the layout page
        fullWidthHeader={
          <div
            className={`header-container${
              _.get(contentfulBlog, "imageHasText") ? " image-has-text" : ""
            }`}
          >
            {/* Background Image from contentful with a default background if user doesn't supply one */}
            {_.get(contentfulBlog, "image") ? (
              <AppImage
                className="hero-image"
                image={_.get(contentfulBlog, "image")}
              />
            ) : (
              <MissingHeroImage />
            )}
            {!_.get(contentfulBlog, "imageHasText") ? headerContent : null}
          </div>
        }
      >
        <div className="blog-body-wrapper">
          <div className="blog-body">
            {_.get(contentfulBlog, "imageHasText") ? headerContent : null}
            <RichTextRender contentfulRichText={contentfulRichText} />
          </div>
        </div>
        {(_.get(contentfulBlog, "authors") || []).map((author, index) => {
          return (
            <div key={index} className="author-info-wrapper">
              <div className="author-info">
                <div className="author-image-wrapper">
                  <AppImage image={_.get(author, "image")} />
                  <div className="author-links-mobile">
                    {authorSocialLinks(author)}
                  </div>
                </div>
                <div className="author-details">
                  <div className="author-top-section">
                    <div className="author-left-side">
                      <span className="written-by">WRITTEN BY</span>
                      <div className="author-name">{_.get(author, "name")}</div>
                    </div>
                    <div className="author-links-desktop">
                      {authorSocialLinks(author)}
                    </div>
                  </div>
                  <div className="author-bio">
                    {_.get(author, "bio.internal.content")}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Layout>
    </>
  );
};

export default BlogTemplate;

export const query = graphql`
  query blogPostQuery($title: String) {
    contentfulBlog(title: { title: { eq: $title } }) {
      title {
        internal {
          content
        }
      }
      description
      image {
        title
        description
        file {
          url
        }
      }
      imageHasText
      authors {
        name
        image {
          title
          description
          fluid(maxWidth: 108, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        bio {
          internal {
            content
          }
        }
        socialLinks {
          title
          url
        }
      }
      date
      readTime
      bodyText {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            description
            file {
              fileName
              url
            }
          }
        }
      }
    }
  }
`;
